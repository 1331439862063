import { fork, spawn, take } from "redux-saga/effects";
import {
  SUBSCRIBE,
  UNSUBSCRIBE,
  UNSUBSCRIBE_BY_ID,
  WATCH_PRICES,
  currentPriceSlice,
} from "./redux";
import { store } from "../../../store";
import { notifications } from "@mantine/notifications";
import { sleep } from "../../helpers";
import { runJobEvery } from "../../helpers";

// todo: ? add restart on error / close

class BWS {
  static socket;
  static symbols = [];

  static async startNewConnection() {
    if (BWS.socket) {
      BWS.socket.close();
      await sleep(1000);
    }

    BWS.socket = new WebSocket("wss://stream.binance.com:9443/ws");

    BWS.socket.onopen = (e) => {
      let symbols = [...new Set(Object.values(BWS.symbols))];

      if (!symbols.length) return;

      console.log("bws: subscribe to all", symbols);

      BWS.socket.send(
        JSON.stringify({
          method: "SUBSCRIBE",
          params: symbols.map((symbol) => `${symbol.toLowerCase()}@trade`),
          id: `add_all`,
        })
      );
    };

    BWS.socket.onerror = (e) => {
      console.log("bws: websocket Error", e);
      // notifications.show({
      //   title: `BWS error: ${e.reason}`,
      //   color: "red",
      //   autoClose: 6000,
      // });
    };

    BWS.socket.onping = () => {
      console.log("bws: ping from server");
      BWS.socket.pong();
    };

    BWS.socket.onpong = () => {
      console.log("bws: pong from server");
    };

    BWS.socket.onclose = (e) => {
      console.log("bws: websocket is closed", e);
      // notifications.show({
      //   title: `BWS is closed: ${e.reason}`,
      //   color: "red",
      //   autoClose: 6000,
      // });
      BWS.startNewConnection();
    };

    BWS.socket.onmessage = (e) => {
      const data = JSON.parse(e.data);

      if (data.e === "trade") {
        // console.log(data.s, data.p);
        store.dispatch(
          currentPriceSlice.actions.setSymbolPrice({
            symbol: data.s,
            price: parseFloat(data.p),
          })
        );
      } else {
        console.log("bws: uknown message", data);
      }
    };
  }

  static async init() {
    await BWS.startNewConnection();

    window.addEventListener("offline", () => {
      console.log("bws: offline mode");
    });
    window.addEventListener("online", () => {
      BWS.socket?.close();
    });
  }

  static subscribe({ symbol, id }) {
    if (BWS.symbols[id] !== symbol) {
      const prevSymbols = Object.values(BWS.symbols);

      BWS.symbols[id] = symbol;

      if (!prevSymbols.includes(symbol) && BWS.socket?.readyState === 1) {
        console.log("bws: subscribe to", symbol);
        BWS.socket.send(
          JSON.stringify({
            method: "SUBSCRIBE",
            params: [`${symbol.toLowerCase()}@trade`],
            id: `add_symbol`,
          })
        );
      }
    }
  }

  static unsubscribe({ symbol, id }) {
    if (BWS.symbols[id] === symbol) {
      delete BWS.symbols[id];

      if (
        !Object.values(BWS.symbols).includes(symbol) &&
        BWS.socket?.readyState === 1
      ) {
        console.log("bws: unsubscribe from", symbol);
        BWS.socket.send(
          JSON.stringify({
            method: "UNSUBSCRIBE",
            params: [`${symbol.toLowerCase()}@trade`],
            id: `remove_symbol`,
          })
        );
      }
    }
  }

  static unsubscribeById({ id }) {
    if (!BWS.symbols[id]) return;
    BWS.unsubscribe({ symbol: BWS.symbols[id], id });
  }
}

class BBWS {
  static socket;
  static symbols = [];

  static get isConnected() {
    return BBWS.socket?.readyState === 1;
  }

  static async startNewConnection() {
    if (BBWS.socket) {
      BBWS.socket.close();
      await sleep(1000);
    }

    BBWS.socket = new WebSocket("wss://stream.bybit.com/v5/public/spot");

    BBWS.socket.onopen = (e) => {
      let symbols = [...new Set(Object.values(BBWS.symbols))];

      if (!symbols.length) return;

      console.log("bbws: subscribe to all", symbols);

      BBWS.socket.send(
        JSON.stringify({
          req_id: `add_all`,
          op: "subscribe",
          args: symbols.map((symbol) => `tickers.${symbol}`),
        })
      );
    };

    BBWS.socket.onerror = (e) => {
      console.log("bbws: websocket Error", e);
      // notifications.show({
      //   title: `BBWS error: ${e.reason}`,
      //   color: "red",
      //   autoClose: 6000,
      // });
    };

    BBWS.socket.onping = () => {
      console.log("bbws: ping from server");
      BBWS.socket.pong();
    };

    BBWS.socket.onpong = () => {
      console.log("bbws: pong from server");
    };

    BBWS.socket.onclose = (e) => {
      console.log("bbws: websocket is closed", e);
      // notifications.show({
      //   title: `BBWS is closed: ${e.reason}`,
      //   color: "red",
      //   autoClose: 6000,
      // });
      BBWS.startNewConnection();
    };

    BBWS.socket.onmessage = (e) => {
      const data = JSON.parse(e.data);

      if (data.topic?.includes("tickers")) {
        // console.log(data.data.symbol, data.data?.lastPrice);
        store.dispatch(
          currentPriceSlice.actions.setSymbolPrice({
            symbol: data.data?.symbol,
            price: parseFloat(data.data?.lastPrice),
          })
        );
      } else {
        console.log("bbws: unknown message", data);
      }
    };
  }

  static async init() {
    await BBWS.startNewConnection();

    window.addEventListener("offline", () => {
      console.log("bbws: offline mode");
    });
    window.addEventListener("online", () => {
      BBWS.socket?.close();
    });
  }

  static subscribe({ symbol, id }) {
    if (BBWS.symbols[id] !== symbol) {
      const prevSymbols = Object.values(BBWS.symbols);

      BBWS.symbols[id] = symbol;

      if (!prevSymbols.includes(symbol) && BBWS.socket?.readyState === 1) {
        console.log("bbws: subscribe to", symbol);
        BBWS.socket.send(
          JSON.stringify({
            req_id: `add_symbol`,
            op: "subscribe",
            args: [`tickers.${symbol}`],
          })
        );
      }
    }
  }

  static unsubscribe({ symbol, id }) {
    if (BBWS.symbols[id] === symbol) {
      delete BBWS.symbols[id];

      if (
        !Object.values(BBWS.symbols).includes(symbol) &&
        BBWS.socket?.readyState === 1
      ) {
        console.log("bbws: unsubscribe from", symbol);
        BBWS.socket.send(
          JSON.stringify({
            req_id: `remove_symbol`,
            op: "unsubscribe",
            args: [`tickers.${symbol}`],
          })
        );
      }
    }
  }

  static unsubscribeById({ id }) {
    if (!BBWS.symbols[id]) return;
    BBWS.unsubscribe({ symbol: BBWS.symbols[id], id });
  }

  static async sendPings() {
    await runJobEvery({
      seconds: 15,
      logger: console.log,
      job: async () => {
        if (BBWS.isConnected) {
          BBWS.socket.send(JSON.stringify({ op: "ping" }));
        }
      },
    });
  }
}

class MexcWS {
  static socket;
  static symbols = [];

  static get isConnected() {
    return MexcWS.socket?.readyState === 1;
  }

  static async startNewConnection() {
    if (MexcWS.socket) {
      MexcWS.socket.close();
      await sleep(1000);
    }

    MexcWS.socket = new WebSocket("wss://wbs.mexc.com/ws");

    MexcWS.socket.onopen = (e) => {
      let symbols = [...new Set(Object.values(MexcWS.symbols))];

      if (!symbols.length) return;

      console.log("MexcWS: subscribe to all", symbols);

      MexcWS.socket.send(
        JSON.stringify({
          id: 1,
          method: "SUBSCRIPTION",
          params: symbols.map(
            (symbol) => `spot@public.miniTicker.v3.api@${symbol}@UTC+0`
          ),
        })
      );
    };

    MexcWS.socket.onerror = (e) => {
      console.log("MexcWS: websocket Error", e);
      // notifications.show({
      //   title: `MexcWS error: ${e.reason}`,
      //   color: "red",
      //   autoClose: 6000,
      // });
    };

    MexcWS.socket.onping = () => {
      console.log("MexcWS: ping from server");
      MexcWS.socket.pong();
    };

    MexcWS.socket.onpong = () => {
      console.log("MexcWS: pong from server");
    };

    MexcWS.socket.onclose = (e) => {
      console.log("MexcWS: websocket is closed", e);
      // notifications.show({
      //   title: `MexcWS is closed: ${e.reason}`,
      //   color: "red",
      //   autoClose: 6000,
      // });
      MexcWS.startNewConnection();
    };

    MexcWS.socket.onmessage = (e) => {
      const data = JSON.parse(e.data);

      // console.log(data);

      if (data.c?.includes("miniTicker")) {
        // console.log(data.s, data.d.p);
        store.dispatch(
          currentPriceSlice.actions.setSymbolPrice({
            symbol: data.s,
            price: parseFloat(data.d?.p),
          })
        );
      } else {
        console.log("MexcWS: unknown message", data);
      }
    };
  }

  static async init() {
    await MexcWS.startNewConnection();

    window.addEventListener("offline", () => {
      console.log("MexcWS: offline mode");
    });
    window.addEventListener("online", () => {
      MexcWS.socket?.close();
    });
  }

  static subscribe({ symbol, id }) {
    if (MexcWS.symbols[id] !== symbol) {
      const prevSymbols = Object.values(MexcWS.symbols);

      MexcWS.symbols[id] = symbol;

      if (!prevSymbols.includes(symbol) && MexcWS.socket?.readyState === 1) {
        console.log("MexcWS: subscribe to", symbol);
        MexcWS.socket.send(
          JSON.stringify({
            id: 2,
            method: "SUBSCRIPTION",
            params: [`spot@public.miniTicker.v3.api@${symbol}@UTC+0`],
          })
        );
      }
    }
  }

  static unsubscribe({ symbol, id }) {
    if (MexcWS.symbols[id] === symbol) {
      delete MexcWS.symbols[id];

      if (
        !Object.values(MexcWS.symbols).includes(symbol) &&
        MexcWS.socket?.readyState === 1
      ) {
        console.log("MexcWS: unsubscribe from", symbol);
        MexcWS.socket.send(
          JSON.stringify({
            id: 3,
            method: "UNSUBSCRIPTION",
            params: [`spot@public.miniTicker.v3.api@${symbol}@UTC+0`],
          })
        );
      }
    }
  }

  static unsubscribeById({ id }) {
    if (!MexcWS.symbols[id]) return;
    MexcWS.unsubscribe({ symbol: MexcWS.symbols[id], id });
  }

  static async sendPings() {
    await runJobEvery({
      seconds: 15,
      logger: console.log,
      job: async () => {
        if (MexcWS.isConnected) {
          MexcWS.socket.send(JSON.stringify({ op: "ping" }));
        }
      },
    });
  }
}

export function* subscribeToPrice() {
  while (true) {
    const action = yield take(SUBSCRIBE);

    if (action.platform === "binance") {
      yield spawn(BWS.subscribe, action);
    } else if (action.platform === "bybit") {
      yield spawn(BBWS.subscribe, action);
    } else if (action.platform === "mexc") {
      yield spawn(MexcWS.subscribe, action);
    }
  }
}

export function* unsubscribeFromPrice() {
  while (true) {
    const action = yield take(UNSUBSCRIBE);

    if (action.platform === "binance") {
      yield spawn(BWS.unsubscribe, action);
    } else if (action.platform === "bybit") {
      yield spawn(BBWS.subscribe, action);
    } else if (action.platform === "mexc") {
      yield spawn(MexcWS.subscribe, action);
    }
  }
}

export function* unsubscribeById() {
  while (true) {
    const action = yield take(UNSUBSCRIBE_BY_ID);

    yield spawn(BWS.unsubscribeById, action);
    yield spawn(BBWS.unsubscribeById, action);
    yield spawn(MexcWS.unsubscribeById, action);
  }
}

export function* watchPrices() {
  yield take(WATCH_PRICES);

  BWS.init();
  BBWS.init();
  MexcWS.init();
}

BBWS.sendPings();
