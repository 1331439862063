import { useState, useEffect, useMemo } from "react";
import {
  Button,
  Grid,
  Center,
  TextInput,
  Group,
  SegmentedControl,
} from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";
import { IconRefresh } from "@tabler/icons-react";
import uniqid from "uniqid";

import Mongo from "../common/sevices/mongo";
import { useLastPrice } from "../common/hooks";
import { diffToPercent, getDurationInMin } from "../common/helpers";

import MultipleTokensAlert from "./MultipleTokensAlert";
import PumpTable from "./Pump";
import UptrendTable from "./Uptrend";
import DowntrendTable from "./Downtrend";
import DiscountsTable from "./Discounts";
import FlashCrashTable from "./FlashCrash";
import BottomTable from "./Bottom";

const lastPriceGetters = {
  gis: ({ alert }) => alert.lastPrice,
  fis: ({ alert }) => alert.lastPrice,
  discounts: ({ alert }) => {
    if (alert.lastPrice) return alert.lastPrice;
    else {
      return alert.major.max / (alert.major.fall / 100 + 1);
    }
  },
  bottom: ({ alert }) => {
    if (alert.lastPrice) return alert.lastPrice;
    else {
      return alert.major.max / (alert.major.fall / 100 + 1);
    }
  },
  dropdown: ({ alert }) => alert.candle.closePrice,
  growing: ({ alert }) => alert.lastAlertPrice,
  falling: ({ alert }) => alert.lastAlertPrice,
};
const sortFuncs = {
  gis: ({ sortDir, sortBy }) => {
    if (sortDir === "des") return (a, b) => b[sortBy] - a[sortBy];
    else if (sortDir === "asc") return (b, a) => b[sortBy] - a[sortBy];
  },
  fis: ({ sortDir, sortBy }) => {
    if (sortDir === "des") return (a, b) => b[sortBy] - a[sortBy];
    else if (sortDir === "asc") return (b, a) => b[sortBy] - a[sortBy];
  },
  discounts: ({ sortDir, sortBy }) => {
    if (sortDir === "des") {
      if (["delta"].includes(sortBy)) return (a, b) => b[sortBy] - a[sortBy];
      return (a, b) => b.major[sortBy] - a.major[sortBy];
    } else if (sortDir === "asc") {
      if (["delta"].includes(sortBy)) return (a, b) => a[sortBy] - b[sortBy];
      return (a, b) => a.major[sortBy] - b.major[sortBy];
    }
  },
  bottom: ({ sortDir, sortBy }) => {
    if (sortDir === "des") {
      if (["delta"].includes(sortBy)) return (a, b) => b[sortBy] - a[sortBy];
      return (a, b) => b.major[sortBy] - a.major[sortBy];
    } else if (sortDir === "asc") {
      if (["delta"].includes(sortBy)) return (a, b) => a[sortBy] - b[sortBy];
      return (a, b) => a.major[sortBy] - b.major[sortBy];
    }
  },
  dropdown: ({ sortDir, sortBy }) => {
    if (sortDir === "des") return (a, b) => b[sortBy] - a[sortBy];
    else if (sortDir === "asc") return (b, a) => b[sortBy] - a[sortBy];
  },
  growing: ({ sortDir, sortBy }) => {
    if (sortDir === "des") return (a, b) => b[sortBy] - a[sortBy];
    else if (sortDir === "asc") return (b, a) => b[sortBy] - a[sortBy];
  },
  falling: ({ sortDir, sortBy }) => {
    if (sortDir === "des") return (a, b) => b[sortBy] - a[sortBy];
    else if (sortDir === "asc") return (b, a) => b[sortBy] - a[sortBy];
  },
};
const defaultSortBys = {
  gis: "growth",
  fis: "fall",
  discounts: "growth",
  bottom: "highLow",
  dropdown: "droppedBy",
  growing: "lastAlertDate",
  falling: "lastAlertDate",
};

export default function AlertView() {
  const [searchParams, _] = useSearchParams();

  // get last prices
  const [bnPrices, bbPrices, mexcPrices] = useLastPrice();

  // stared
  const [starred, setStarred] = useState({});

  // general menu
  const [category, setCategory] = useState(
    searchParams.get("category") || "gis"
  );
  const [categoryInterval, setCategoryInterval] = useState("1d");
  const [categoryDate, setCategoryDate] = useState("today");
  const [date, setDate] = useState(new Date());

  // 1. custom alert
  const [customAlertId, setCustomAlertId] = useState(() =>
    searchParams.get("id")
  );
  const [debCustomAlertId] = useDebouncedValue(customAlertId, 300);
  const [customAlert, setCustomAlert] = useState(null);
  // 2. interval alerts
  const [intervalAlerts, setIntervalAlerts] = useState(null);
  // 3. assemble alerts
  const [assembleAlerts, setAssembleAlerts] = useState(null);

  // selected alerts
  const [selectedAlerts, setSelectedAlerts] = useState(null);
  // current alert
  const [alert, setAlert] = useState(null);

  // 1. get custom alert
  useEffect(
    function getCustomAlert() {
      if (!debCustomAlertId) return;

      const getAlert = async () => {
        try {
          if (!Mongo.client) await Mongo.connect();
          const collection = Mongo.client
            .db(Mongo.dbs.main)
            .collection("alertsHistory");
          const alert = await collection.findOne({
            _id: Mongo.objectId(debCustomAlertId),
          });
          setCustomAlert(alert);
          console.log(alert);
        } catch (e) {
          console.log(e);
          setCustomAlert(null);
        }
      };

      getAlert();
    },
    [debCustomAlertId]
  );

  // 2. get interval alerts
  useEffect(
    function getIntervalAlerts() {
      if (!["gis", "fis", "discounts", "bottom", "dropdown"].includes(category))
        return;

      if (!!intervalAlerts?.[category]?.[categoryInterval]) return;

      const getIntervalAlerts = async () => {
        try {
          if (!Mongo.client) await Mongo.connect();
          const collection = Mongo.client
            .db(Mongo.dbs.main)
            .collection("alertsHistory");
          const alerts = await collection.find(
            {
              type: category,
              interval: categoryInterval,
            },
            { limit: 100, sort: { _id: -1 } }
          );

          let updatedIntervalAlerts = {
            ...(intervalAlerts || {}),
            [category]: {
              ...(intervalAlerts?.[category] || {}),
              [categoryInterval]: alerts,
            },
          };
          setIntervalAlerts(updatedIntervalAlerts);
          console.log(alerts);
        } catch (e) {
          console.log(e);
          let updatedIntervalAlerts = {
            ...(intervalAlerts || {}),
            [category]: {
              ...(intervalAlerts?.[category] || {}),
              [categoryInterval]: null,
            },
          };
          setIntervalAlerts(updatedIntervalAlerts);
        }
      };

      getIntervalAlerts();
    },
    [category, categoryInterval]
  );

  // 3. get assemble alerts
  useEffect(
    function getAssembleAlerts() {
      if (!["growing", "falling"].includes(category)) return;

      if (!!assembleAlerts?.[category]) return;

      const getAssembleAlerts = async () => {
        try {
          if (!Mongo.client) await Mongo.connect();
          const collection = Mongo.client
            .db(Mongo.dbs.main)
            .collection("alertsHistory");
          const alerts = await collection.find(
            {
              type: category,
              date: { $gte: new Date(Date.now() - 24 * 60 * 60 * 1000) },
            },
            { sort: { _id: -1 } }
          );

          let assembleAlert = {
            _id: uniqid(),
            type: category,
            openTime: new Date(Date.now() - 24 * 60 * 60 * 1000),
            closeTime: new Date(),
            alert: {},
          };
          if (["growing"].includes(category)) {
            let map = {};
            // map => {[symbol]: { <first>, <last>, <all> }
            // assemble alert => {minorGrowth, majorGrowth, firstAlertPrice, lastAlertPrice, flDelta, count, avgPcIn5m, lastAlertTfs, lastAlertDate}
            for (let alert of alerts) {
              let { symbol } = alert;
              if (!map[symbol]) {
                map[symbol] = { first: null, last: null, all: [] };
              }
              if (!map[symbol].first || alert.bell < map[symbol].first.bell) {
                map[alert.symbol].first = alert;
              }
              if (!map[symbol].last || alert.bell > map[symbol].last.bell) {
                map[symbol].last = alert;
              }
              map[symbol].all.push(alert);
            }
            for (let symbol in map) {
              let { first, last, all } = map[symbol];
              assembleAlert.alert[symbol] = {
                symbol,
                platform: first.platform,
                quoteVolume: last.quoteVolume,
                minorGrowth: last.alert.minor.growth,
                majorGrowth: last.alert.major.growth,
                firstAlertPrice: first.alert.major.lastPrice,
                lastAlertPrice: last.alert.major.lastPrice,
                flDelta: diffToPercent(
                  last.alert.major.lastPrice / first.alert.major.lastPrice
                ),
                count: all.length,
                avgPcIn5m: all.reduce(
                  (avg, alert) => alert.alert.pcIn5m / all.length + avg,
                  0
                ),
                lastAlertTfs: last.alert.tfs,
                lastAlertDate: last.date.getTime(),
                flTime: getDurationInMin(last.date, first.date),
              };
            }
            assembleAlert.count = Object.keys(assembleAlert.alert).length;
          }

          let updatedAssembleAlerts = {
            ...(assembleAlerts || {}),
            [category]: [assembleAlert],
          };
          setAssembleAlerts(updatedAssembleAlerts);
          console.log(updatedAssembleAlerts);
        } catch (e) {
          console.log(e);
          let updatedAssembleAlerts = {
            ...(assembleAlerts || {}),
            [category]: null,
          };
          setAssembleAlerts(updatedAssembleAlerts);
        }
      };

      getAssembleAlerts();
    },
    [category]
  );

  // set delta to current alert
  const updatedAlert = useMemo(
    function updateCurrentAlert() {
      if (!alert?.alert || !Object.values(alert.alert).length) return;
      let updated = JSON.parse(JSON.stringify(alert));
      for (let symbol in updated.alert) {
        let { platform } = updated.alert[symbol];

        let lastPrice = lastPriceGetters[category]({
          alert: updated.alert[symbol],
        });

        let newPrice;
        if (platform === "binance") newPrice = bnPrices[symbol];
        if (platform === "bybit") newPrice = bbPrices[symbol];
        if (platform === "mexc") newPrice = mexcPrices[symbol];

        let delta;
        if (newPrice) delta = diffToPercent(newPrice / lastPrice, 0);
        updated.alert[symbol] = { ...updated.alert[symbol], delta };
      }
      return updated;
    },
    [alert, bnPrices, bbPrices, mexcPrices]
  );

  // set selected alert
  useEffect(() => {
    if (category === "custom") {
      setSelectedAlerts([customAlert]);
      setAlert(customAlert);
    } else if (
      ["gis", "fis", "discounts", "bottom", "dropdown"].includes(category)
    ) {
      let alerts = intervalAlerts?.[category]?.[categoryInterval];
      alerts = alerts?.filter((a) => {
        let aDate = new Date(a.date);
        return (
          aDate.getDate() === date.getDate() &&
          aDate.getMonth() === date.getMonth() &&
          aDate.getFullYear() === date.getFullYear()
        );
      });
      alerts?.sort(
        (a, b) =>
          new Date(a.openTime).getTime() - new Date(b.openTime).getTime()
      );
      setSelectedAlerts(alerts);
      setAlert(alerts?.[0]);
    } else if (["growing", "falling"].includes(category)) {
      let alerts = assembleAlerts?.[category];
      setSelectedAlerts(alerts);
      setAlert(alerts?.[0]);
    }
  }, [
    category,
    categoryInterval,
    intervalAlerts,
    assembleAlerts,
    date,
    customAlert,
  ]);

  return (
    <Grid>
      <Grid.Col span={12}>
        <Group>
          <SegmentedControl
            value={category}
            onChange={setCategory}
            data={[
              { label: "Pump", value: "growing" },
              { label: "Dump", value: "falling" },
              { label: "Uptrend", value: "gis" },
              { label: "Downtrend", value: "fis" },
              { label: "Discount", value: "discounts" },
              { label: "Bottom", value: "bottom" },
              { label: "Flash Crash", value: "dropdown" },
              { label: "Custom", value: "custom" },
            ]}
          />
          {category === "custom" && (
            <TextInput
              value={customAlertId}
              onChange={(e) => setCustomAlertId(e.target.value)}
            />
          )}
          {["gis", "fis", "discounts", "bottom", "dropdown"].includes(
            category
          ) && (
            <SegmentedControl
              value={categoryInterval}
              onChange={setCategoryInterval}
              data={[
                { label: "1w", value: "1w" },
                { label: "1d", value: "1d" },
                { label: "4h", value: "4h" },
              ]}
            />
          )}
          {["gis", "fis", "discounts", "bottom", "dropdown"].includes(
            category
          ) && (
            <SegmentedControl
              onChange={(value) => {
                if (value === "today") {
                  setDate(new Date());
                } else if (value === "yesterday") {
                  const d = new Date();
                  d.setDate(d.getDate() - 1);
                  setDate(d);
                }
                setCategoryDate(value);
              }}
              data={[
                { label: "Today", value: "today" },
                { label: "Yesterday", value: "yesterday" },
                { label: "Custom", value: "custom" },
              ]}
            />
          )}
          {["gis", "fis", "discounts", "bottom", "dropdown"].includes(
            category
          ) && (
            <DatePickerInput
              placeholder="Pick a date"
              value={date}
              disabled={categoryDate !== "custom"}
              onChange={setDate}
              excludeDate={(d) => {
                if (
                  !intervalAlerts?.[category]?.[categoryInterval]?.some(
                    (a) =>
                      new Date(a.date).getDate() === d.getDate() &&
                      new Date(a.date).getMonth() === d.getMonth()
                  )
                ) {
                  return true;
                }
              }}
            />
          )}
          {/*{category !== "custom" && (*/}
          {/*  <Button color="gray" variant="light" onClick={() => {}}>*/}
          {/*    <IconRefresh size="1.2rem" />*/}
          {/*  </Button>*/}
          {/*)}*/}
        </Group>
      </Grid.Col>
      {selectedAlerts?.length ? (
        <Grid.Col span={12}>
          <Group>
            {selectedAlerts.map((a) => {
              if (!a) return;
              let dayMonth = new Date(a.openTime).toLocaleString("en-GB", {
                day: "numeric",
                month: "short",
              });
              let hrStart = new Date(a.openTime).toLocaleString(undefined, {
                hour: "numeric",
                minute: undefined,
              });
              let hrEnd = new Date(
                new Date(a.closeTime).getTime() + 1
              ).toLocaleString(undefined, {
                hour: "numeric",
                minute: undefined,
              });
              let dateClm = `⏱️ ${dayMonth} ‧ ${hrStart} - ${hrEnd}`;
              return (
                <Button
                  color={alert._id === a._id ? "green" : "blue"}
                  key={a._id.toString()}
                  onClick={() => setAlert(a)}
                >
                  {dateClm} ({Object.values(a.alert).length})
                </Button>
              );
            })}
          </Group>
        </Grid.Col>
      ) : null}

      <Grid.Col span={12}>
        {alert?.type === "growing" && (
          <MultipleTokensAlert
            // tableSpan={6}
            title="Pump"
            alert={updatedAlert || alert}
            starred={starred}
            setStarred={setStarred}
            AlertTable={PumpTable}
            sortFunc={sortFuncs.growing}
            defaultSortBy={defaultSortBys.growing}
          />
        )}
        {alert?.type === "gis" && (
          <MultipleTokensAlert
            title="Uptrend"
            alert={updatedAlert || alert}
            starred={starred}
            setStarred={setStarred}
            AlertTable={UptrendTable}
            sortFunc={sortFuncs.gis}
            defaultSortBy={defaultSortBys.gis}
          />
        )}
        {alert?.type === "fis" && (
          <MultipleTokensAlert
            title="Downtrend"
            alert={updatedAlert || alert}
            starred={starred}
            setStarred={setStarred}
            AlertTable={DowntrendTable}
            sortFunc={sortFuncs.fis}
            defaultSortBy={defaultSortBys.fis}
          />
        )}
        {alert?.type === "discounts" && (
          <MultipleTokensAlert
            title="Discounts"
            alert={updatedAlert || alert}
            starred={starred}
            setStarred={setStarred}
            AlertTable={DiscountsTable}
            sortFunc={sortFuncs.discounts}
            defaultSortBy={defaultSortBys.discounts}
          />
        )}
        {alert?.type === "dropdown" && (
          <MultipleTokensAlert
            title="Flash Crash"
            alert={updatedAlert || alert}
            starred={starred}
            setStarred={setStarred}
            AlertTable={FlashCrashTable}
            sortFunc={sortFuncs.dropdown}
            defaultSortBy={defaultSortBys.dropdown}
          />
        )}
        {alert?.type === "bottom" && (
          <MultipleTokensAlert
            title="Bottom"
            alert={updatedAlert || alert}
            starred={starred}
            setStarred={setStarred}
            AlertTable={BottomTable}
            sortFunc={sortFuncs.bottom}
            defaultSortBy={defaultSortBys.bottom}
          />
        )}
      </Grid.Col>
    </Grid>
  );
}
