import { React } from "react";
import { Select, Text } from "@mantine/core";

export default function AmountUsdPicker({ value, onChange }) {
  return (
    <Select
      label={<Text fw={700}>Amount ($)</Text>}
      maxDropdownHeight={400}
      placeholder="Amount ($)"
      data={[
        "0",
        "10",
        "50",
        "100",
        "200",
        "300",
        "400",
        "500",
        "750",
        "1000",
        "2500",
        "5000",
        "7500",
        "10000",
      ]}
      value={value}
      onChange={onChange}
    />
  );
}
