import { useEffect, useState } from "react";
import axios from "axios";

export function useLastPrice() {
  const [bnPrices, setBnPrices] = useState({});
  const [bbPrices, setBbPrices] = useState({});
  const [mexcPrices, setMexcPrices] = useState({});

  useEffect(() => {
    async function setLastPrice() {
      // if (!alert?.alert || !Object.values(alert)?.length) return;
      let [bn, bb, mexc] = await Promise.allSettled([
        axios("https://api.binance.com/api/v3/ticker/price"),
        axios("https://api.bybit.com/v5/market/tickers", {
          params: { category: "spot" },
        }),
        axios("/api/mexc-symbol-price-tickers"),
      ]);
      bn = bn?.status === "fulfilled" ? bn?.value?.data : null;
      bb = bb?.status === "fulfilled" ? bb?.value?.data?.result?.list : null;
      mexc = mexc?.status === "fulfilled" ? mexc?.value?.data : null;
      if (bn?.length) {
        let map = {};
        for (let t of bn)
          if (t.symbol.includes("USDT")) map[t.symbol] = t.price;
        setBnPrices(map);
      }
      if (bb?.length) {
        let map = {};
        for (let t of bb)
          if (t.symbol.includes("USDT")) map[t.symbol] = t.lastPrice;
        setBbPrices(map);
      }
      if (mexc?.length) {
        let map = {};
        for (let t of mexc)
          if (t.symbol.includes("USDT")) map[t.symbol] = t.price;
        setMexcPrices(map);
      }
    }

    setLastPrice();

    const intervalId = setInterval(setLastPrice, 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return [bnPrices, bbPrices, mexcPrices];
}
