import { Center, Grid, Text, Title, ActionIcon } from "@mantine/core";
import { IconStarFilled } from "@tabler/icons-react";

import Table, { TH } from "../../common/components/Table";
import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";

export default function UptrendTable({
  token,
  tokens,
  sortBy,
  sortDir,
  setSortBy,
  setSortDir,
  starred,
  setStarred,
}) {
  return (
    <Table>
      <Thead
        sortBy={sortBy}
        sortDir={sortDir}
        setSortBy={setSortBy}
        setSortDir={setSortDir}
      />
      <Tbody
        token={token}
        tokens={tokens}
        starred={starred}
        setStarred={setStarred}
      />
    </Table>
  );
}

const Thead = ({ sortBy, sortDir, setSortBy, setSortDir }) => {
  return (
    <thead>
      <tr>
        <TH title="💎" />
        <TH
          title="%"
          sortBy="growth"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="Δ"
          sortBy="delta"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="dr"
          sortBy="durationInD"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="lc"
          sortBy="lcGrowth"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="avg"
          sortBy="speedPerCandle"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH title="btn" />
      </tr>
    </thead>
  );
};

const Tbody = ({ token, tokens, starred, setStarred }) => {
  return (
    <tbody>
      {(token ? [token] : tokens).map(
        ({
          symbol,
          platform,
          growth,
          delta,
          lastPrice,
          durationInD,
          lcGrowth,
          speedPerCandle,
        }) => {
          return (
            <tr
              key={symbol}
              style={
                {
                  // backgroundColor: starred[symbol] ? "#D1FFBD" : "white",
                }
              }
            >
              <td>
                <Text size="sm" weight={700} align="center">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://www.tradingview.com/chart/b865o21d/?symbol=${platform.toUpperCase()}:${symbol}`}
                    target="_blank"
                  >
                    {symbol.replace("USDT", "")}
                  </a>
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  ↑ {growth.toFixed(0)}%{" "}
                </Text>
              </td>
              <td>
                <Text
                  size="sm"
                  weight={600}
                  color={delta >= 0 ? "green.9" : "orange.9"}
                >
                  {delta === undefined
                    ? ""
                    : delta >= 0
                    ? `+${delta}%`
                    : `${delta}%`}
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {durationInD <= 1
                    ? `${Math.ceil(durationInD * 24)}h`
                    : `${Math.ceil(durationInD)}d`}
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {lcGrowth.toFixed(1)}%
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {speedPerCandle.toFixed(1)}%
                </Text>
              </td>
              <td>
                <Center>
                  <ActionIcon
                    variant="transparent"
                    color={starred[symbol] ? "teal.7" : "gray.2"}
                    onClick={() => {
                      console.log(starred[symbol]);
                      setStarred({ ...starred, [symbol]: !starred[symbol] });
                    }}
                  >
                    <IconStarFilled size="1.2rem" />
                  </ActionIcon>
                </Center>
              </td>
            </tr>
          );
        }
      )}
    </tbody>
  );
};
