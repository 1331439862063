import { Center, Grid, Text, Title, ActionIcon } from "@mantine/core";
import { IconStarFilled } from "@tabler/icons-react";

import Table, { TH } from "../../common/components/Table";
import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { getDurationInMin } from "../../common/helpers";
import { time } from "uniqid";

export default function PumpTable({
  token,
  tokens,
  sortBy,
  sortDir,
  setSortBy,
  setSortDir,
  starred,
  setStarred,
}) {
  return (
    <Table>
      <Thead
        sortBy={sortBy}
        sortDir={sortDir}
        setSortBy={setSortBy}
        setSortDir={setSortDir}
      />
      <Tbody
        token={token}
        tokens={tokens}
        starred={starred}
        setStarred={setStarred}
      />
    </Table>
  );
}

const Thead = ({ sortBy, sortDir, setSortBy, setSortDir }) => {
  return (
    <thead>
      <tr>
        <TH title="💎" />
        <TH
          title="mnr"
          sortBy="minorGrowth"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="mjr"
          sortBy="majorGrowth"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="flΔ"
          sortBy="flDelta"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        {/*<TH*/}
        {/*  title="Δ"*/}
        {/*  sortBy="delta"*/}
        {/*  currentSortBy={sortBy}*/}
        {/*  currentSortDir={sortDir}*/}
        {/*  setSortBy={setSortBy}*/}
        {/*  setSortDir={setSortDir}*/}
        {/*/>*/}
        <TH title="tf" sortBy="tf" />
        <TH
          title="cnt"
          sortBy="count"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="⌛"
          sortBy="flTime"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="🕒"
          sortBy="lastAlertDate"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH title="btn" />
      </tr>
    </thead>
  );
};

const Tbody = ({ token, tokens, starred, setStarred }) => {
  return (
    <tbody>
      {(token ? [token] : tokens).map(
        ({
          symbol,
          platform,
          delta,
          minorGrowth,
          majorGrowth,
          avgPcIn5m,
          flDelta,
          count,
          lastAlertTfs,
          lastAlertDate,
          flTime,
        }) => {
          let timeAgo = getDurationInMin(new Date(), lastAlertDate);
          if (timeAgo < 60) timeAgo = `${Math.round(timeAgo)}m`;
          else timeAgo = `${Math.round(timeAgo / 60)}h`;

          if (flTime < 60) flTime = `${Math.round(flTime)}m`;
          else flTime = `${Math.round(flTime / 60)}h`;

          return (
            <tr key={symbol}>
              <td>
                <Text size="sm" weight={700} align="center">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://www.tradingview.com/chart/b865o21d/?symbol=${platform.toUpperCase()}:${symbol}`}
                    target="_blank"
                  >
                    {symbol.replace("USDT", "")}
                  </a>
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {minorGrowth.toFixed(0)}%{" "}
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {majorGrowth.toFixed(0)}%{" "}
                </Text>
              </td>
              <td>
                <Center>
                  <Text
                    size="sm"
                    weight={600}
                    color={flDelta >= 0 ? "green.9" : "orange.9"}
                  >
                    {flDelta === undefined
                      ? ""
                      : flDelta >= 0
                      ? `+${flDelta.toFixed(0)}%`
                      : `${flDelta.toFixed(0)}%`}
                  </Text>
                </Center>
              </td>
              {/*<td>*/}
              {/*  <Center>*/}
              {/*    <Text*/}
              {/*      size="sm"*/}
              {/*      weight={600}*/}
              {/*      color={delta >= 0 ? "green.9" : "orange.9"}*/}
              {/*    >*/}
              {/*      {delta === undefined*/}
              {/*        ? ""*/}
              {/*        : delta >= 0*/}
              {/*        ? `+${delta.toFixed(0)}%`*/}
              {/*        : `${delta.toFixed(0)}%`}*/}
              {/*    </Text>*/}
              {/*  </Center>*/}
              {/*</td>*/}
              <td>
                <Text size="sm" weight={600} align="center">
                  {lastAlertTfs[0]}
                </Text>
              </td>
              <td>
                <Center>
                  <Text size="sm" weight={600}>
                    {count}
                  </Text>
                </Center>
              </td>
              <td>
                <Center>
                  <Text size="sm" weight={600}>
                    {flTime}
                  </Text>
                </Center>
              </td>
              <td>
                <Center>
                  <Text size="sm" weight={600}>
                    {timeAgo}
                  </Text>
                </Center>
              </td>
              <td>
                <Center>
                  <ActionIcon
                    variant="transparent"
                    color={starred[symbol] ? "teal.7" : "gray.2"}
                    onClick={() => {
                      console.log(starred[symbol]);
                      setStarred({ ...starred, [symbol]: !starred[symbol] });
                    }}
                  >
                    <IconStarFilled size="1.2rem" />
                  </ActionIcon>
                </Center>
              </td>
            </tr>
          );
        }
      )}
    </tbody>
  );
};
