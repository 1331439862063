import { Anchor, Button, Table as MantineTable, Text } from "@mantine/core";
import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";

export function TH({
  title,
  size = "sm",
  sortBy,
  currentSortBy,
  currentSortDir,
  setSortBy,
  setSortDir,
}) {
  const onClick = () => {
    if (!sortBy) return;
    if (sortBy === currentSortBy) {
      if (currentSortDir === "asc") setSortDir("des");
      else setSortDir("asc");
    } else {
      setSortBy(sortBy);
      setSortDir("des");
    }
  };

  const isSelected = sortBy && currentSortBy === sortBy;

  return (
    <th onClick={onClick}>
      <Text
        align="center"
        fw={700}
        // size={size}
        style={{
          cursor: "pointer",
          color: isSelected ? "black" : "inherit",
          textDecoration: isSelected ? "underline" : "inherit",
        }}
      >
        {title}{" "}
        {isSelected ? (
          currentSortDir === "des" ? (
            <IconArrowDown size="12" />
          ) : (
            <IconArrowUp size="12" />
          )
        ) : null}
      </Text>
    </th>
  );
}

export function TradingViewLink({ symbol, title }) {
  return (
    <Text fw={700} align="center">
      <a
        style={{ textDecoration: "none" }}
        href={`https://www.tradingview.com/chart/b865o21d/?symbol=BINANCE:${symbol}`}
        target="_blank"
      >
        {title || symbol.replace("USDT", "")}
      </a>
    </Text>
  );
}

export function BinanceLink({ symbol, title }) {
  return (
    <Text fw={700} align="center">
      <a
        style={{ textDecoration: "none" }}
        href={`https://www.binance.com/en/trade/${symbol.replace(
          "USDT",
          ""
        )}_USDT`}
        target="_blank"
      >
        {title || symbol.replace("USDT", "")}
      </a>
    </Text>
  );
}

export function TradeLink({ symbol, title }) {
  return (
    <Text fw={700} align="center">
      <a
        style={{ textDecoration: "none" }}
        href={`/trade?symbol=${symbol}&type=real`}
        target="_blank"
      >
        {title || symbol.replace("USDT", "")}
      </a>
    </Text>
  );
}

export function TradeButton({ symbol, title }) {
  return (
    <Anchor href={`/trade?symbol=${symbol}&type=real`} target="_blank">
      <Button variant="light" color="green">
        {title || symbol.replace("USDT", "")}
      </Button>
    </Anchor>
  );
}

Table.TH = TH;
Table.TradingViewLink = TradingViewLink;
Table.BinanceLink = BinanceLink;
Table.TradeButton = TradeButton;
Table.TradeLink = TradeLink;

export function Table({ children, ...props }) {
  return (
    <MantineTable highlightOnHover withBorder withColumnBorders {...props}>
      {children}
    </MantineTable>
  );
}

export default Table;
