import { useEffect, useState } from "react";
import { Text, Box, Button, Grid, MediaQuery } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  IconEyeOff,
  IconArrowsExchange,
  IconRefresh,
  IconMasksTheater,
  IconMasksTheaterOff,
} from "@tabler/icons-react";

import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { CREATE_ORDER, createOrder } from "../../common/data/orders/redux";
import {
  subscribeToPrice,
  unsubscribeFromPrice,
  unsubscribeById,
} from "../../common/data/currentPrice/redux";

import SymbolPicker from "../SymbolPicker";
import AmountPicker from "../AmountPicker";
import AmountUsdPicker from "../AmountUsdPicker";
import RealFakePicker from "../RealFakePicker";
import ActionPicker from "../ActionPicker";
import { StrategyPickerModal, StrategyPickerBoard } from "../StrategyPicker";
import AmountPercentPicker from "../AmountPercentPicker";
import useWebSocket from "react-use-websocket";
import { toFixed } from "../../common/helpers";
import { useMediaQuery } from "@mantine/hooks";

export default function NewOrder({ hideForm }) {
  const dispatch = useDispatch();

  const [searchParams, _] = useSearchParams();

  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  const buyProcess = useSelector((state) => state.processes[CREATE_ORDER]);

  const [symbol, setSymbol] = useState(
    () => searchParams.get("symbol") || "BTCUSDT"
  );
  const [debouncedSymbol] = useDebouncedValue(symbol, 300);
  const [type, setType] = useState(() => searchParams.get("type") || "real");
  const [platform, setPlatform] = useState(
    () => searchParams.get("platform") || "binance"
  );
  const [action, setAction] = useState(
    () => searchParams.get("action") || "buy"
  );
  const [amount, setAmount] = useState("0");
  const [amountUsd, setAmountUsd] = useState("100");
  const [amountPercent, setAmountPercent] = useState("0");
  const [showStrategyPicker, setShowStrategyPicker] = useState(false);
  const [buyStrategies, setBuyStrategies] = useState([]);
  const [sellStrategies, setSellStrategies] = useState([]);
  const [repeatStrategies, setRepeatStrategies] = useState([]);

  let currentPrice = useSelector((state) => {
    return state.currentPrice[symbol];
  });
  currentPrice = currentPrice || 0;
  // console.log(currentPrice);

  // binance price
  // const { lastMessage: binanceMessage } = useWebSocket(
  //   `wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@kline_1s`
  // );
  // const binanceData = binanceMessage && JSON.parse(binanceMessage.data);
  // const binancePrice = parseFloat(binanceData?.k?.c) || 0;
  // const currentPrice = platform === "binance" ? binancePrice : 0;

  useEffect(
    function updateSymbol() {
      if (searchParams.get("symbol")) {
        setSymbol(searchParams.get("symbol"));
      }
    },
    [searchParams.get("symbol")]
  );

  useEffect(
    function updatePlatform() {
      if (searchParams.get("platform")) {
        setPlatform(searchParams.get("platform"));
      }
    },
    [searchParams.get("platform")]
  );

  useEffect(
    function resetRepeatStrategies() {
      if (!buyStrategies.length && !sellStrategies.length) {
        setRepeatStrategies([]);
      }
    },
    [buyStrategies, sellStrategies]
  );

  useEffect(
    function resetStrategiesOnSingleSell() {
      if (action === "sell") {
        setBuyStrategies([]);
        setRepeatStrategies([]);
      }
    },
    [action]
  );

  useEffect(() => {
    if (!debouncedSymbol || !platform) return;
    dispatch(unsubscribeById({ id: "new_order" }));
    dispatch(
      subscribeToPrice({ symbol: debouncedSymbol, id: "new_order", platform })
    );
  }, [debouncedSymbol, platform]);

  const onChangeSymbol = (symbol) => {
    if (!symbol || !symbol.toUpperCase().includes("USDT")) {
      return;
    }

    setSymbol(symbol.toUpperCase());
  };

  const create = () => {
    dispatch(
      createOrder({
        symbol,
        platform,
        amount,
        amountUsd,
        amountPercent,
        action,
        isFake: type === "fake",
        buyStrategies: buyStrategies,
        sellStrategies: sellStrategies,
        repeatStrategies: repeatStrategies,
      })
    );
  };

  return (
    <>
      <StrategyPickerModal
        show={showStrategyPicker}
        currentPrice={currentPrice}
        buyStrategies={buyStrategies}
        setBuyStrategies={setBuyStrategies}
        sellStrategies={sellStrategies}
        setSellStrategies={setSellStrategies}
        repeatStrategies={repeatStrategies}
        setRepeatStrategies={setRepeatStrategies}
        onClose={() => setShowStrategyPicker(false)}
        onSubmit={() => setShowStrategyPicker(false)}
        disableBuyStrategies={action === "sell"}
        disableRepeatStrategies={action === "sell"}
      ></StrategyPickerModal>
      <Grid>
        <Grid.Col span={12}>
          <TradingViewAdvancedChartWidget
            symbol={symbol}
            platform={platform.toUpperCase()}
            width="100%"
            height={isSmallerThanLarge ? "400px" : "500px"}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Box shadow="xs">
            <Grid>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={hideForm}
                  >
                    <IconEyeOff />
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() =>
                      platform === "binance"
                        ? setPlatform("bybit")
                        : platform === "bybit"
                        ? setPlatform("mexc")
                        : setPlatform("binance")
                    }
                  >
                    {platform}
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() => {
                      setAmountPercent("0");
                      setAction((action) =>
                        action === "buy" ? "sell" : "buy"
                      );
                    }}
                  >
                    <IconArrowsExchange />
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() =>
                      setType((type) => (type === "real" ? "fake" : "real"))
                    }
                  >
                    {
                      type === "real"
                        ? "real"
                        : // <IconMasksTheaterOff />
                          "fake"
                      // <IconMasksTheater />
                    }
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <SymbolPicker value={symbol} onChange={onChangeSymbol} />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountUsdPicker
                  value={amountUsd}
                  onChange={(value) => {
                    setAmount("0");
                    setAmountUsd(value);
                    setAmountPercent("0");
                  }}
                />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountPicker
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setAmountUsd("0");
                    setAmountPercent("0");
                  }}
                />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountPercentPicker
                  disabled={action === "buy"}
                  value={amountPercent}
                  onChange={(value) => {
                    setAmount("0");
                    setAmountUsd("0");
                    setAmountPercent(value);
                  }}
                />
              </Grid.Col>
              <Grid.Col xl={4} lg={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="light"
                    color={
                      type === "fake"
                        ? "gray"
                        : action === "buy"
                        ? "teal"
                        : "red"
                    }
                    loading={buyProcess?.inProcess}
                    disabled={buyProcess?.inProcess}
                    onClick={create}
                  >
                    {action === "buy" ? "Buy" : "Sell"}{" "}
                    {+amountUsd
                      ? `${amountUsd}$ ${symbol.replace("USDT", "")}`
                      : +amountPercent
                      ? `${amountPercent}% ${symbol.replace("USDT", "")}`
                      : +amount
                      ? `${amount} ${symbol.replace("USDT", "")} = ${
                          currentPrice ? toFixed(amount * currentPrice, 0) : "_"
                        }$`
                      : null}
                  </Button>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
        <Grid.Col span={12}>
          <StrategyPickerBoard
            buyStrategies={buyStrategies}
            sellStrategies={sellStrategies}
            repeatStrategies={repeatStrategies}
            openModal={() => setShowStrategyPicker(true)}
            isFake={type === "fake"}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}
